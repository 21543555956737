<template>
  <div class="row">
    <!-- start chat users-->
    <div class="col-xl-4 col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="media mb-3">
            <b-avatar size="md" variant="dark" class="text-light mr-1" style="cursor: pointer"
            ><span class="">{{ admin.name | initials }}</span></b-avatar
            >
            <div class="media-body">
              <h5 class="mt-0 mb-0 font-15">
                <router-link to="/contacts/profile" class="text-reset"
                >{{admin.name}}</router-link
                >
              </h5>
              <p class="mt-1 mb-0 text-muted font-14">
                <small class="mdi mdi-circle text-success"></small> Online
              </p>
            </div>
            <div>
              <a href="javascript: void(0);" class="text-reset font-20">
                <i class="mdi mdi-cog-outline"></i>
              </a>
            </div>
          </div>

          <!-- start search box -->
          <form class="search-bar mb-3">
            <div class="position-relative">
              <input
                  type="text"
                  class="form-control form-control-light"
                  placeholder="Search drivers .."
                  @keyup="onSearch"
              />
              <span class="mdi mdi-magnify"></span>
            </div>
          </form>
          <!-- end search box -->
          <template v-if="searchMode && isSearching">
            <skeleton :count="5"></skeleton>
          </template>
          <template v-if="searchMode && !isSearching ">
            <h6 class="font-13 text-muted text-uppercase mb-2">Drivers</h6>
            <div class="row">
              <div class="col">
                <simplebar data-simplebar style="max-height: 498px">
                  <a
                      href="javascript:void(0);"
                      class="text-body"
                      v-for="(driver, index) in drivers"
                      :key="driver['id']"
                      @click="setupDriverChat(driver)"
                  >
<!--                    @click="chatUsername(item.name, item.image)" -->
                    <div class="media p-2">
                      <div class="position-relative">
                        <b-avatar size="md" variant="dark" class="text-light mr-2" style="cursor: pointer"
                        ><span class="">{{ driver.name | initials }}</span></b-avatar
                        >
                      </div>
                      <div class="media-body">
                        <h5 class="mt-0 mb-0 font-14">
                          <span
                              class="float-right text-muted font-weight-normal font-12"
                          >
<!--                            You can put something here like notification if needed -->

                          </span
                          >
                          {{driver.name}} {{driver['other_names']}}
                        </h5>
                        <p class="mt-1 mb-0 text-muted font-14">
                          <span class="w-75">status: {{driver.status}}</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </simplebar>
                <!-- end slimscroll-->
              </div>
              <!-- End col -->
            </div>
          </template>

          <h6 class="font-13 text-muted text-uppercase mb-2">Recent Chats</h6>
          <!-- users -->
          <div class="row">
            <div class="col">
              <simplebar data-simplebar style="max-height: 498px">
                <a
                    href="javascript:void(0);"
                    class="text-body"
                    v-for="(item, index) in chatUsers"
                    :key="index"
                    @click="chatDriver(item)"
                >
                  <div class="media p-2 border-bottom">
                    <div class="position-relative">
<!--                        <span-->
<!--                            class="user-status"-->
<!--                            :class="{-->
<!--                            online: item.status === 'online',-->
<!--                            busy: item.status === 'offline',-->
<!--                            'do-not-disturb': item.status === 'do-not-disturb',-->
<!--                          }"-->
<!--                        ></span>-->
                      <b-avatar size="md" variant="light" class="text-dark mr-2" style="cursor: pointer"
                      ><span class="">{{ item.name | initials }}</span></b-avatar
                      >
                    </div>
                    <div class="media-body">
                      <h5 class="mt-0 mb-0 font-14">
                        <div
                            class="float-right text-muted font-weight-normal font-12"
                        >
                          <template v-if="item['pendingSupportMessagesForAdmin'] > 0">
                            <br>
                            <span class="badge badge-pill badge-success float-right mt-2"> {{item['pendingSupportMessagesForAdmin']}}</span>
                          </template>
                        </div
                        >
                        {{ item.name }}
                      </h5>
                      <p class="mt-1 mb-0 text-muted font-14">
<!--                        <span class="w-75">{{ item.lastMessage === '' ? 'Click to start chatting' : item.lastMessage }}</span>-->
                        {{formatTimestamp(item.lastMessageTime)}}
                      </p>
                    </div>
                  </div>
                </a>
              </simplebar>
              <!-- end slimscroll-->
            </div>
            <!-- End col -->
          </div>
          <!-- end users -->
        </div>
        <!-- end card-body-->
      </div>
      <!-- end card-->
    </div>
    <!-- end chat users-->

    <!-- chat area -->
    <div class="col-xl-8 col-lg-8">
      <div class="card">
        <div class="card-body py-2 px-3 border-bottom border-light" v-if="chatUser">
          <div class="media py-1" >
            <b-avatar size="md" variant="light" class="text-dark mr-1" style="cursor: pointer"
            ><span class="">{{ chatUser.name | initials }}</span></b-avatar
            >
            <div class="media-body">
              <h5 class="mt-2 mb-0 font-15">
                <router-link to="/contacts/profile" class="text-reset">
                  {{ chatUser.name }}
                </router-link>
              </h5>
<!--              <p class="mt-1 mb-0 text-muted font-12">-->
<!--                <small class="mdi mdi-circle" :class="{'text-success': chatUser.status === 'online', 'text-danger': chatUser.status === 'offline'}"></small>-->
<!--                {{chatUser.status}}-->
<!--              </p>-->
            </div>

          </div>
        </div>
        <div class="card-body">
          <div v-if="url" style="max-height: 589px; background: white">
            <div class="mb-3 text-light">
              <h4>
                <i
                    @click="url = null"
                    class="fas fa-times mr-5"
                /><span class="font-weight-bold">Preview</span>
              </h4>
            </div>
            <div v-if="progress>0"
                class="progress mb-2"
                style="height: 15px; border-radius: 0"
            >
              <div
                   class="progress-bar bg-orange"
                   role="progressbar"
                   :style="{ width: progress + '%' }"
                   :aria-valuenow="progress"
                   aria-valuemin="0"
                   aria-valuemax="100"
              >
                          <span class="progress-indicator"
                          >Upload is  {{progress.toFixed(2) }} % done</span
                          >
              </div>
            </div>

            <div id="preview" class="mb-3" style="display: flex; justify-content: center; align-items: center;">
              <img class="img-fluid" style="max-height: 387px;"
                   v-if="url && url.type === 'image'"
                   :src="url.url"
              />
              <div v-if="url && url.type !== 'image'">
                <i
                    style="font-size: 10rem"
                    class="fas fa-file-alt mt-5"
                />
              </div>
            </div>
            <div class="text-center">{{url.name}}</div>

          </div>

            <simplebar data-simplebar style="max-height: 460px" id="scrollID" v-else>
              <template v-if="chatUser">

                <ul class="conversation-list chat-app-conversation">
                <li
                    class="clearfix"
                    v-for="(data, index) in chatMessagesData"
                    :key="index"
                    :class="{ odd: data.sentBy === 'admin' }"
                >
                  <template v-if="data.type === 'support'">
                  <div class="chat-avatar">
                    <b-avatar size="md" variant="light" class="text-dark mr-1" style="cursor: pointer"
                    >
                      <template v-if="data.sentBy === 'admin'">
                        <span class="">{{ admin.name | initials }}</span>
                      </template>
                      <template v-else>
                        <span class="">{{ chatUser.name | initials }}</span>
                      </template>


                    </b-avatar
                    >
                  </div>
                  <div class="conversation-text">
                    <div class="ctext-wrap">
                      <b-dropdown
                          class="conversation-actions" :class="{ 'bg-light':data.sentBy === 'admin' }"
                          toggle-class="btn-sm btn-link text-reset p-0"
                          variant="black"
                          right
                      >
                        <template v-slot:button-content>
                          <i class="fa fa-chevron-down font-18"></i>
                        </template>
                        <button class="dropdown-item" @click="getReply(data)">Reply</button>
                        <button class="dropdown-item" v-if="data.sentBy === 'admin'" @click="deleteChatMessage(data)">Delete</button>
                      </b-dropdown>
                      <div class="message-content">
                        <div class="_3Ppzm" v-if="data.reply">
                          <div style="font-size: 10px">
                            {{data.sentBy === 'admin' ? "You" : ((data || {}).reply || {}).name}}
                          </div>
                          <div v-if="data.reply.message">{{ ((data || {}).reply || {}).message }}</div>
                          <div v-if="data.reply.hasFile">
                            <div>{{data.reply.file.name}}</div>
                          </div>

                        </div>
                        <div v-if="data.file">
                          <audio
                              controls
                              v-if="data.file.type === 'mp3'"
                          >
                            <source
                                :src="data.file.url"
                                type="audio/ogg"
                            />
                            <source
                                :src="data.file.url"
                                type="audio/mpeg"
                            />
                          </audio>
                          <video
                              v-if="data.file.type === 'mp4'"
                              style="height: 500px"
                              class="w-100"
                              controls
                              controlsList="nodownload"
                          >
                            <source
                                :src="data.file.url"
                                type="video/mp4"
                            />
                            <source
                                :src="data.file.url"
                                type="video/ogg"
                            />
                          </video>
                          <div v-if="getFileType(data.file.type) ==='image'">
                            <img
                                :src="data.file.url"
                                class="img-fluid"
                                style="width: 330px; max-height: 248px; object-fit: contain;"
                                alt=""
                            />
                            <div
                                class="text-muted font-weight-medium"
                            >{{ data.file.name }}</div
                            >
                          </div>

                          <div
                              class="card mt-2 mb-1 shadow-none border text-left"
                              v-else
                          >
                            <div class="p-2">
                              <div class="row align-items-center">
                                <div class="col-auto">
                                  <div class="avatar-sm">
                              <span class="avatar-title bg-primary rounded"
                              >{{ data.file.type }}</span
                              >
                                  </div>
                                </div>
                                <div class="col pl-0">
                                  <a
                                      class="text-muted font-weight-medium"
                                  >{{ data.file.name }}</a
                                  >
                                  <p class="mb-0">{{ data.file.size | fileSize }}</p>
                                </div>
                                <div class="col-auto">
                                  <!-- Button -->
                                  <a
                                      :href="data.file.url"
                                      class="btn btn-link btn-lg text-muted"
                                      target="_blank"
                                      :download="data.file.name"
                                  >
                                    <i class="fas fa-download"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <p><i>{{ data.message }}</i></p>
                      <small>{{ formatTimestamp(data.sentAt) }}</small>
                    </div>
                  </div>
                  </template>


                </li>
              </ul>
              </template>
            </simplebar>
            <div class="row">
              <div class="col">
                <div class="mt-2 bg-light p-3 rounded">
                  <div style="display: flex" v-if="reply">
                    <div class="_3Ppzm _3OUXw" style="flex: 1">
                      <div class="_26iqs">
                        <small class="reply_phone text-green">{{reply.senderName}}</small>
                      </div>
                      <div class="_31DtU">
                        <span class="reply-text font-weight-bold" v-if="reply.message">{{ reply.message }}</span>
                        <div v-if="reply.hasFile">
                          <div>{{reply.file.name}}</div>
                        </div>
                      </div>
                    </div>
                    <div
                        class="UUcHl"
                        style="margin-top: 5px; margin-left: 20px"
                    >
                      <div role="button" @click="reply = ''">
                          <span data-testid="x" data-icon="x" class=""
                          ><svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                          >
                              <path
                                  fill="currentColor"
                                  d="M19.1 17.2l-5.3-5.3 5.3-5.3-1.8-1.8-5.3 5.4-5.3-5.3-1.8 1.7 5.3 5.3-5.3 5.3L6.7 19l5.3-5.3 5.3 5.3 1.8-1.8z"
                              /></svg
                          ></span>
                      </div>
                    </div>
                  </div>

                  <form
                      class="needs-validation"
                      @submit.prevent="formSubmit"
                      name="chat-form"
                      id="chat-form"
                  >
                    <div class="row">
                      <div class="col mb-2 mb-sm-0">
                        <input
                            type="text"
                            v-model="form.message"
                            ref="send"
                            class="form-control border-0"
                            placeholder="Enter your text"
                        />

                      </div>

                      <div class="col-sm-auto">
                        <div class="btn-group">
                          <button @click="triggerFile" type="button" class="btn btn-light" v-if="chatUser">
                            <i class="fe-paperclip"></i>
                          </button>
                          <button
                              type="submit"
                              class="btn btn-success chat-send btn-block"
                              :disabled="!chatUser || disableMsgSendBtn"
                          >
                            <i class="fe-send"></i>
                          </button>
                        </div>
                      </div>
                      <!-- end col -->
                    </div>
                    <!-- end row-->
                  </form>
                </div>
              </div>
              <!-- end col-->
            </div>
          <input
              id="file-select"
              ref="fileSelect"
              style="visibility: hidden"
              type="file"
              @change="selectFile"
          />

          <!-- end row -->
        </div>
        <!-- end card-body -->
      </div>
      <!-- end card -->
    </div>
    <!-- end chat area-->
  </div>
</template>

<script>
import {getDatabase, get, ref, push, set, onValue, update, remove} from "firebase/database";
import { getStorage, ref as storageRef, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

import { Skeleton } from 'vue-loading-skeleton';
import moment from "moment";
import {chatItem, chatUserItem, getFileType} from "../../../../utils/functions";
/**
 * Starter component
 */
export default {
  components:{
    Skeleton
  },
  data() {
    return {
      chatUsers: [],
      chatMessagesData: [],
      form: {
        message: "",
      },
      username: "Designer",
      chatUser: null,
      searchMode: false,
      mDrivers: [],
      reply:null,
      fileUpload: "",
      url: null,
      progress: 0,
      disableMsgSendBtn: false,
    };
  },
  computed: {
    admin(){
      return this.$store.getters.GET_USER_INFO
    },
    drivers() {
      return this.$store.getters.GET_DRIVERS;
    },
    isSearching() {
      return this.$store.getters.SECTION_LOADER;
    }
  },
  watch: {
    chatUser: function (value) {
        if(!value)return;
        this.getSelectedUserMessages()
    }
  },
  methods: {
    getFileType,

    chatDriver(driver){
        this.chatUser = driver;
       console.log(driver)
        this.updateAdminPendingMessages()
       this.markUserChatAsRead(driver)
    },

    triggerFile() {
      this.$refs.fileSelect.value = null;
      this.$refs.fileSelect.click();
    },

    selectFile(e) {
      this.fileUpload = e.target.files[0];
      this.url = {
        url: "",
        type: "",
        name:""
      };
      let fileType = this.fileUpload.name.split(".").pop();
      const fileSizeInBytes = this.fileUpload.size;
      this.url.name = this.fileUpload.name;
      this.url.size = fileSizeInBytes;


      if (fileType === "jpeg" || fileType === "png" || fileType === "jpg") {
        this.url.url = URL.createObjectURL(this.fileUpload);
        this.url.type = "image";
      } else {
        this.url.type = fileType;
        this.url.url = "../../../public/assets2/img/clip.png";
      }
    },

    markUserChatAsRead(driver){
      const payload = {
        type: 'support',
        id: driver['id']
      }
      this.$store.dispatch('setNotificationMessagesAsRead', payload)
    },

    async uploadFile() {
      // Create a reference to the Firebase Storage
      this.disableMsgSendBtn = true;
      const firebaseStorage = getStorage();
      try {
        // Create a child reference inside 'users' with the driver ID and file name
        const fileRef = storageRef(firebaseStorage, `chatFiles/${this.fileUpload.name}`);

        // Upload the file to Firebase Storage using uploadBytesResumable
        const uploadTask = uploadBytesResumable(fileRef, this.fileUpload);

        // Listen for state changes and progress updates
        uploadTask.on('state_changed',
            snapshot => {
              // Get the progress (percentage) of the upload
              this.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log('Upload is ' + progress.toFixed(2) + '% done');
            },
            error => {
              console.error("Error uploading file:", error);
            },
            async () => {
              try {
                // Upload completed successfully, get the download URL
                const downloadURL = await getDownloadURL(fileRef);

                // console.log('File uploaded successfully. Download URL:', downloadURL);
                this.saveMessage(downloadURL)


              } catch (error) {
                console.error("Error getting download URL:", error);
              }
            }
        );
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    },


    setupDriverChat(driver) {

      const db = getDatabase();

      const userRef = ref(db, 'users/' + driver.id);

      const data =  chatUserItem(driver, 'support');


      // check if user ref has already not been created
      // if not, create a chat channel for user
      get(userRef).then((snapshot) => {

        console.log('setupDriverChat called', this.chatUser)

        if (!snapshot.exists()) {
          set(userRef, data).then(() => {
            this.chatUser = data;
          }).then(() => {
            this.searchMode = false
          })
        }else{
          this.updateAdminPendingMessages()
        }

      }).catch((error) => {
        console.error(error);
      });

    },

    updateAdminPendingMessages(newValue = 0){
      console.log('updateAdminPendingMessages called', this.chatUser)
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);
      const updateData = {
        'pendingSupportMessagesForAdmin': newValue,
      }
      update(userRef, updateData);
    },

    formatTimestamp(value){
      return moment(value).calendar();
    },

    onSearch(event){
      console.log(event.target.value)
      let value = event.target.value;
      if(value === ''){
        this.searchMode = false;
        this.$store.dispatch('clearDrivers')
        return;
      }
      this.searchMode = true
      this.$store.dispatch('searchDriver', value)
    },
    /**
     * Char form Submit
     */
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      if(!this.url){
        if(this.form.message === ''){
          return;
        }
        if(!this.chatUser)
          return;

        this.saveMessage();
      }else{
        this.uploadFile();
      }

    },

    saveMessage(fileUrl=''){
      let fileDetails=''
      
      if(fileUrl){
       fileDetails = {
          type: this.url.type,
          url: fileUrl,
          name: this.url.name,
          size: this.url.size
        }
      }
      const db = getDatabase();

      const chatRef = ref(db, 'chats/' + this.chatUser.id);
      const newChatRef = push(chatRef);

      const item = chatItem(this.form.message, this.chatUser.id, this.chatUser.name, 'support','',this.reply,fileDetails);


      set(newChatRef, item).then(() => {
        // Data saved successfully!
        this.form.message = '';
        this.scrollToBottom();
        this.updateDriverUser(item['message'], item['sentAt']);

      }).catch((error) => {
        // The write failed...
        console.log(error)
      });
      this.reply ='';
      this.disableMsgSendBtn = false;
      this.url=null;
      this.progress =0;

    },

    deleteChatMessage(data){
      // Create a reference to the Firebase Realtime Database
      let deleteConfirmation = confirm('Are you sure you want to delete this chat message?');
      if (deleteConfirmation){
        const db = getDatabase();

        const chatMessageId = this.chatUser.id;

        // Create a reference to the chat message you want to delete
        const chatRef = ref(db, 'chats/' + chatMessageId+ '/' + data.key);

        // Delete the chat message from the Realtime Database
        remove(chatRef)
            .then(() => {
              // If successful, the message will be removed from the Realtime Database.
              console.log("Chat message deleted successfully!");
            })
            .catch(error => {
              console.error("Error deleting chat message:", error);
            });
        if(data.hasFile){
          this.deleteFile(data.file.name);
        }
      }

    },

     async deleteFile(fileName) {
       const firebaseStorage = getStorage();
       try {
         // Get the reference to the file in Firebase Storage using the download URL
         const fileRef = storageRef(firebaseStorage, 'chatFiles/'+ fileName);
         await this.deleteFileFromStorage(fileRef);

       } catch (error) {
         console.error('Error deleting file:', error);
       }
     },

    async  deleteFileFromStorage(fileRef) {
      try {
        // Delete the file from Firebase Storage
        await deleteObject(fileRef);

        console.log('File deleted successfully.');
      } catch (error) {
        console.error('Error deleting file from Storage:', error);
      }
    },

    updateDriverUser(lastMessage, lastMessageTime){
      if(!this.chatUser)return;

      const db = getDatabase();
      const userRef = ref(db, 'users/' + this.chatUser.id);

      get(userRef).then((snapshot) => {
        if (snapshot.exists()) {

          const map = snapshot.val();
          let pendingSupportMessagesForDriver = map['pendingSupportMessagesForDriver'];
          pendingSupportMessagesForDriver = pendingSupportMessagesForDriver + 1;

          const updateData = {
            'lastMessage': lastMessage,
            'lastMessageTime': lastMessageTime,
            'pendingSupportMessagesForDriver': pendingSupportMessagesForDriver,
          }

          update(userRef, updateData);

        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

      this.updateAdminPendingMessages();

    },

    getReply(item) {
      this.reply = item;
      this.$refs.send.focus();
    },


    getPreviousChatUsers(){
      const db = getDatabase();
      const usersRef = ref(db, 'users');
      onValue(usersRef, (snapshot) => {
        const data = snapshot.val();
        if(!data){
          return;
        }
        const arr = [];
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            let user = data[key];
            arr.push(user)
          }

        }

        arr.sort(function(b, a){return a['lastMessageTime']-b['lastMessageTime']})
        console.log('data', arr)
        this.chatUsers = arr
      });
    },

    getSelectedUserMessages(){

      this.chatMessagesData = [];
      const db = getDatabase();
      const chatRef = ref(db, 'chats/' + this.chatUser.id);
      onValue(chatRef, (snapshot) => {
        const data = snapshot.val();
        if(!data){
          return;
        }

        // console.log('data', data);
        //  = data.sort(function(a, b){return a-b});
//// Mark as read
        const arr = [];
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            let chat = data[key];
            chat.key = key;

            if(chat['type'] === 'support'){
              arr.push(chat)
              if(chat['sentTo'] === 'admin' && chat['read'] === false ){

                const updateData = {
                  'recipientId': this.admin.id,
                  'recipientName': this.admin.name,
                  'read': true
                }
                const upRef = ref(db, 'chats/' + this.chatUser.id + '/' + key)
                //update(upRef, updateData);
              }
            }

          }


        }

        arr.sort(function(a, b){return a['sentAt']-b['sentAt']})
        this.chatMessagesData = arr;
        // console.log('chatMessagesData', this.chatMessagesData)
        this.scrollToBottom();

      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        const container = document.querySelector('#scrollID .simplebar-content-wrapper');
        if(!container){
          return;
        }
        // container.scrollTo({ top: 100000000000000000000000000000000, behavior: "smooth"});
        container.scrollTo({ top: 100000000000000000000000000000000});
      })
      //behavior: "smooth"
    },

  },

  created() {
    this.getPreviousChatUsers()
    this.scrollToBottom();
    this.$store.dispatch('setPageTitle','Chat');
  }

};
</script>

<style scoped>
.conversation-actions{
  position: absolute;
  background: #d5eef7;
  z-index: 1;
  right: 5px;
}
._3Ppzm {
  background: #d5eef7;
  border-left: 4px solid #0599a9;
  border-radius: 5px;
  padding: 8px 12px 9px 11px;
  margin-bottom: 10px;
}
</style>

